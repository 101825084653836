import {
  computed,
  ref,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import meterLogStoreModule from '../meterLogStoreModule';

export default function useMeterLogDetailModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'meterLog';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, meterLogStoreModule);
  }

  const toastification = toast();
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const meterLogId = computed(() => props.meterLogId);
  const { t } = useI18nUtils();
  const fetchDetailData = () => {
    if (meterLogId.value) {
      isLoading.value = true;
      store.dispatch('meterLog/getMeterLogDetail', meterLogId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  return {
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchDetailData,
    onOpen,
    isLoading,
    t,
  };
}
