import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import toast from '@/utils/toast';
import moment from 'moment';
import meterStoreModule from '../../meter/meterStoreModule';
import meterLogStoreModule from '../meterLogStoreModule';

export default function useMeterLogList() {
  const STORE_MODULE_NAME = 'meterLog';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, meterLogStoreModule);
  }
  if (!store.hasModule('meter')) {
    store.registerModule('meter', meterStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  const blankItem = {
    disableFilter: false,
    apartment: null,
    room: null,
    meterType: null,
    month: moment(new Date()).format("MM-YYYY"),
    issueDate: moment(new Date()).toDate(),
    noRecordOnly: true,
    active: null,
    meters: [],
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const selectedItem = ref(null);

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'meter-log') && !vm.$can('delete', 'meter-log');

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: t('Công tơ'),
      field: 'meter.name',
    },
    {
      label: t('Số cũ'),
      field: 'firstIndex',
      type: 'number',
    },
    {
      label: t('Số mới'),
      field: 'lastIndex',
      type: 'number',
    },
    {
      label: t('Tiêu thụ'),
      field: 'delta',
      type: 'number',
    },
    {
      label: t('Ngày chốt'),
      field: 'issueDate',
    },
    {
      label: t('Đã duyệt'),
      field: 'approve',
      width: '110px',
      tdClass: 'text-center',
    },

  ];
  const rows = ref([]);
  const analytic = ref(null);
  // filter
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  const location = ref(null);
  const apartment = ref(null);
  const room = ref(null);
  const approveStatus = ref(null);
  const month = ref(moment(new Date()).format("MM-YYYY"));
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: { month: month.value },
    sort: {

    },
    page: 1,
    perPage: 20,
  });

  //   API Call

  const fetchMeterLogs = () => {
    store
      .dispatch('meterLog/fetchMeterLogs', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    store
      .dispatch('meterLog/fetchAnalytics', serverParams.value)
      .then(response => {
        analytic.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchAnalytics();
    fetchMeterLogs();
  };

  const downloadImportTemplate = () => {
    store
      .dispatch('meterLog/generateMeterLogTemplate', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('meterLog/importMeterLogs', formData)
      .then(() => {
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('meterLog/exportMeterLogs', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteMeterLogs = meterlogs => {
    store
      .dispatch('meterLog/deleteMeterLogs', {
        ids: meterlogs.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const approveMeterLogs = (approve, meterLogs) => {
    store
      .dispatch('meterLog/approveMeterLogs', {
        ids: meterLogs.map(_obj => _obj.id),
        approve,
      })
      .then(() => {
        fetchData();
        toastification.showToast(approve === true ? "Duyệt thành công" : "Bỏ duyệt thành công");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = {
      disableFilter: true,
      apartment: val.meter.apartment,
      room: val.meter.room,
      meterType: val.meter.type,
      month: val.month,
      issueDate: val.issueDate,
      meters: [{
        meter: val.meter, firstIndex: val.firstIndex, lastIndex: val.lastIndex, issueDate: val.issueDate, id: val.id, imageFile: null, image: val.image,
      }],
    };
  };

  const onSelectItem = val => {
    selectedItem.value = val;
  };

  const setApproveHidden = items => {
    // Tat ca hoa don dang o trang thai chua duyet thi se hien thi nut approve

    const numOfApprove = items.filter(obj => obj.approve === false).length;
    return !(items.length > 0 && numOfApprove === items.length);
  };

  const setDeclineHidden = items => {
    // Hiển thị khi tất cả hóa đơn ở trạng thái đã duyệt
    const numOfDecline = items.filter(obj => obj.approve === true).length;
    return !(items.length > 0 && numOfDecline === items.length);
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.type = val.value;
    } else {
      delete filter.type;
    }
    updateParams({ filter });
  });
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(approveStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.approve = val.value;
    } else {
      delete filter.approve;
    }
    updateParams({ filter });
  });
  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.month = month.value;
    } else {
      delete filter.month;
    }
    updateParams({ filter });
  });

  return {
    analytic,
    item,
    selectedItem,
    columns,
    rows,
    type,
    location,
    apartment,
    room,
    month,
    approveStatus,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    fetchData,
    downloadImportTemplate,
    importData,
    fetchMeterLogs,
    deleteMeterLogs,
    resetItem,
    onEditItem,
    onSelectItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    approveMeterLogs,
    setApproveHidden,
    setDeclineHidden,
    exportData,
    t,
  };
}
